/* Footer styles */
.footer {
    background: rgba(21, 0, 59, 0.8);
    color: #ffffff;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  
  .footer-logo {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeInLogo 1.5s ease-in-out;
  }
  
  .footer-nav {
    margin-bottom: 20px;
    animation: fadeInNav 2s ease-in-out;
  }
  
  .footer-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
  }
  
  .footer-menu-item {
    animation: slideIn 1.2s ease-in-out;
  }
  
  .footer-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
  }
  
  .footer-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    bottom: 0;
    left: -100%;
    transition: left 0.3s ease;
  }
  
  .footer-link:hover {
    color: #f7e642;
  }
  
  .footer-link:hover::after {
    left: 0;
  }
  
  .footer-social {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    animation: fadeInSocial 2.5s ease-in-out;
  }
  
  .social-icon {
    width: 40px;
    height: 40px;
    background-size: cover;
    transition: transform 0.3s, filter 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.2);
    filter: brightness(1.2);
  }
  
  .facebook-icon {
    background-image: url('../assets/icons8-facebook.svg');
  }
  
  .instagram-icon {
    background-image: url('../assets/icons8-instagram.svg');
  }
  
  .twitter-icon {
    background-image: url('../assets/icons8-twitterx.svg');
  }
  
  .footer-bottom {
    margin-top: 30px;
    font-size: 0.9rem;
    animation: fadeInBottom 3s ease-in-out;
  }
  
  /* Keyframes for animations */
  @keyframes fadeInLogo {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInNav {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInSocial {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInBottom {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-30px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-menu {
      flex-direction: column;
      gap: 10px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .footer-logo {
      font-size: 1.5rem;
    }
  
    .footer-menu-item {
      font-size: 0.9rem;
    }
  
    .social-icon {
      width: 35px;
      height: 35px;
    }
  }
  