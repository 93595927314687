.timeline-section {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .timeline-header {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 80px;
  }
  
  .timeline-header h2 {
    font-size: 2rem;
    color: #00C4CC;
    text-shadow: 2px 2px 10px #00C4CC;
  }
  
  .timeline-header h3 {
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
  }
  
  .timeline {
    position: relative;
    margin-top: 20px;
  }
  
  .timeline-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .left-container {
    justify-content: flex-start;
  }

  .timeline-body:hover {
    background-color: rgba(125, 190, 139, 0.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .right-container-arrow:hover {
    background-color: rgba(125, 190, 139, 0.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .left-container-arrow:hover {
    background-color: rgba(125, 190, 139, 0.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .timeline-circle {
    width: 20px;
    height: 20px;
    background-color: #00C4CC;
    border-radius: 50%;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
  }
  
  .timeline-body {
    background-color: #043f4d;
    padding: 15px 20px;
    border-radius: 6px;
    position: relative;
    width: 100%;
    color: white;
    animation: fadeIn 1s ease forwards;
    opacity: 0;
  }
  
  .timeline-body h2 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .timeline-body small {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #ddd;
  }
  
  .right-container-arrow,
  .left-container-arrow {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 15px;
  }
  
  .right-container-arrow {
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #043f4d;
    left: -8px;
  }
  
  .left-container-arrow {
    border-width: 8px 8px 8px 0;
    border-color: transparent #043f4d transparent transparent;
    right: -8px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

    /* Animation for image fade-in from left */
    @keyframes fadeInLeft {
      from {
        opacity: 0;
        transform: translateX(-100px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  
  @media screen and (max-width: 768px) {
    .timeline-section {
      max-width: 100%;
      margin-right: 50px;
    }

    .timeline-header {
        margin-left: 50px;
    }
  
    .timeline-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .timeline-body {
      font-size: 0.9rem;
    }
  
    .timeline-header h2 {
      font-size: 1.5rem;
    }
  
    .timeline-header h3 {
      font-size: 1rem;
    }
  }
  