/* Home section container */
.home-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #B2DC7E;
  flex-wrap: wrap;
  overflow: hidden;
}

/* Home content styles */
.home-content {
  max-width: 50%;
  margin-top: 5px;
  margin-left: 50px;
  opacity: 0; 
  animation: slideIn 1.5s forwards ease-out;
}

/* Slide-in animation for home-content */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Typing animation for the heading */
.typing-animation {
  font-size: 70px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: bold;
  color: #07271F;
  /* overflow: hidden; Ensures content doesn't overflow */
  display: inline-block; /* Keeps the text inline and allows line break */
  white-space: nowrap; /* Prevents text from wrapping */
  animation: typing 7s steps(40, end) 1s forwards;
  /* Typing animation only */
}

/* Typing keyframes for multi-line effect */
@keyframes typing {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}


.home-content p {
  font-size: 18px;
  color: #07271F;
  margin-bottom: 50px;
}

.enroll-button {
  padding: 15px 30px;
  background-color: #07271F; 
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.enroll-button:hover {
  background-color: #004b23;
}

/* Home image styles */
.home-image {
  max-width: 40%;
  margin-top: 5px;
}

.home-image img {
  width: 100%;
  border-radius: 10px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1024px) {
  .home-section {
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
  }

  .home-content {
    max-width: 80%;
    margin-left: 0;
    text-align: center;
  }

  .home-content h1 {
    font-size: 50px;
    margin: 0 auto; /* Center horizontally */
    line-height: 1.2; /* Adjust line height for better spacing */
  }

  .home-content p {
    font-size: 16px;
  }

  .home-image {
    max-width: 60%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home-content h1 {
    font-size: 40px;
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Ensure full center alignment */
    line-height: 1.3; /* Slightly increased for readability */
  }

  .home-content p {
    font-size: 14px;
    text-align: center; /* Center the paragraph as well */
  }

  .enroll-button {
    width: 100%;
    padding: 10px 20px;
  }

  .home-image {
    max-width: 70%;
  }


}

@media screen and (max-width: 480px) {
  .home-section {
    padding: 20px 5px;
  }

  .home-content h1 {
    font-size: 30px;
    margin: 0 auto; /* Center horizontally */
    text-align: center;
    line-height: 1.4; /* Increase for better spacing on small screens */
  }

  .home-content p {
    font-size: 12px;
    text-align: center; /* Center the paragraph */
  }

  .enroll-button {
    padding: 8px 16px;
    font-size: 16px;
  }

  .home-image {
    max-width: 70%;
  }
}
