.contact-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/tennis_image.jpg');
    width: 100%;
    height: 100%;
    animation: fadeIn 1.5s ease-in-out;
    padding: 60px;
  }
  
  .contact-overlay {
    background-color: rgba(21, 0, 59, 0.8);
    padding: 60px;
    text-align: center;
    color: #fff;
    position: relative;
    width: 100%;
    max-width: 600px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: slideUp 1.5s ease-out;
    margin-top: 20px;
  }
  
  .contact-form-container {
    margin-top: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-input {
    padding: 15px;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
  }
  
  .contact-button {
    padding: 15px;
    border-radius: 8px;
    background-color: #ff6f61; /* Bright accent color */
    color: #fff;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .contact-button:hover {
    background-color: #ff8a73;
    transform: translateY(-3px);
  }
  
  .social-media-links {
    margin-top: 30px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icon {
    width: 40px;
    height: 40px;
    background-size: cover;
    transition: transform 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.2);
  }
  
  .facebook-icon {
    background-image: url('../assets/icons8-facebook.svg');
  }
  
  .instagram-icon {
    background-image: url('../assets/icons8-instagram.svg');
  }
  
  .twitter-icon {
    background-image: url('../assets/icons8-twitterx.svg');
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  
  @media screen and (max-width: 768px) {
    .contact-section {
      height: auto;
      padding: 40px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .contact-overlay {
      padding: 40px;
      width: 80%;
      margin-left: 0;
      display: column;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media screen and (max-width: 380px) {
    .contact-overlay {
      padding: 30px;
      width: 95%;
      margin-left: 0;
      display: column;
      justify-content: center;
      align-items: center;
    }
  
    .social-icon {
      width: 35px;
      height: 35px;
    }
  
    .contact-input {
      font-size: 0.9rem;
    }
  
    .contact-button {
      font-size: 1rem;
    }
  }
  
  