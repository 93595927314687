/* General styles for the About Section */
.about-section {
    padding: 60px 20px;
    background-size: cover;
    background-position: center;
    background-color: #09261F;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    flex-direction: row; /* Align about-wrapper and timeline side by side */
  }
  
  /* Wrapper to contain About Content and UTR Link Image */
  .about-wrapper {
    display: flex;
    flex-direction: column; 
    max-width: 800px; 
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px; 
  }
  
  /* Flexbox layout for About Content */
  .about-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 30px;
    border-radius: 10px;
    width: 100%; 
    margin-bottom: 20px; 
    animation: fadeInUp 1.2s ease-out;
  }
  
  /* Styling for the left and right sections */
  .about-left,
  .about-right {
    flex: 1;
    padding: 20px;
    min-width: 300px;
    box-sizing: border-box; 
  }
  
  /* Instructor image styling */
  .instructor-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    animation: fadeInLeft 1.5s ease-out;
  }
  
  /* Text styling */
  .about-text h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .about-text h3 {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  
  .about-text p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Stats grid styling */
  .stats-container {
    margin-top: 30px;
  }

  .stats-container h4 {
    text-align: center;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    text-align: center;
  }
  
  .stat-item {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    animation: fadeInUp 1.4s ease-out;
  }
  
  .stat-item span {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: #00C4CC;
  }
  
  .stat-item p {
    margin-top: 10px;
    font-size: 0.9rem;
  }
  
  /* Animation for fading in */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  /* Animation for image fade-in from left */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .stat-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  /* UTR Link Image Styling */
  .utr-link-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    animation: fadeInUp 1.6s ease-out;
    width: 100%; 
    max-width: 600px; 
  }
  
  .utr-link-image h4 {
    margin-bottom: 15px;
    font-size: 1.6rem;
    color: #00C4CC; 
    text-align: center;
  }
  
  .utr-link-image img {
    width: 700px; 
    height: auto;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .utr-link-image img:hover {
    transform: scale(1.1);
    opacity: 0.9;
  }
  
  /* Timeline styles */
  .timeline-wrapper {
    flex: 0 0 300px; 
    max-width: 300px; 
    padding-left: 20px;
    position: relative;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .about-section {
      flex-direction: column; 
      align-items: center; 
    }
  
    .about-wrapper {
      max-width: 100%;
      margin-right: 0; 
    }

    .about-text {
      text-align: center;
    }
  
    .timeline-wrapper {
      max-width: 100%; 
      padding-left: 0;
      margin-top: 20px; 
    }
  
    .utr-link-image {
      max-width: 100%;
    }
  
    .utr-link-image img {
      width: 320px;
    }
  }
  
  /* Container for UTR link image and Kyle serve image */
.images-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px; 
  }
  

  