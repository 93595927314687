/* Navbar container */
.navbar {
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #B2DC7E;
    padding: 20px 40px;
    z-index: 1000;
    margin-right: 40px;
    position: relative;
  }
  
  /* Logo styling */
  .logo {
    width: 2%;
    display: flex;
    margin-left: 50px;
    margin-top: 10px;
  }
  
  /* Nav links styling */
  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: transform 0.3s ease;
  }
  
  /* Hide the nav links on small screens */
  .nav-links.active {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px; /* Adjust to place below navbar */
    right: 0;
    width: 40%;
    background-color: rgba(149, 156, 138, 0.9); /* Semi-transparent background color for dropdown */
    text-align: center;
    box-shadow: 0 4px 6px rgba(252, 250, 250, 0.1);
    margin-top: 20px;
    margin-right: 350px;
    border-radius: 15px;
  }
  
  /* Individual nav link styling */
  .nav-links li {
    margin-right: 20px;
  }
  
  .nav-links li a {
    font-size: 16px;
    color: #000;
    transition: color 0.3s ease;
  }
  
  .nav-links li a:hover {
    color: #c4ebc4;
  }
  
  /* Book button styling */
  .book-button {
    padding: 10px 20px;
    background-color: #07271F;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .book-button:hover {
    background-color: #004b23;
  }
  
  /* Hamburger menu icon styling */
  .hamburger {
    display: none; /* Hide by default */
    flex-direction: column;
    cursor: pointer;
    padding: 10px; /* Padding for semi-transparent effect */
    border: 2px solid rgba(255, 255, 255, 0.5); /* Semi-transparent border */
    border-radius: 8px; /* Rounded corners for aesthetic */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for hamburger */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Blending box shadow */
    position: fixed; /* Fixed position on smaller screens */
    left: 10px; /* Align to the left side */
    top: 10px; /* Align to the top side */
    z-index: 1500; /* Ensure it stays above other elements */
  }
  
  /* Hamburger bars styling */
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: all 0.3s ease;
    border-radius: 1px; /* Rounded bars for better aesthetics */
  }
  


  @keyframes vibrate {
    0% { transform: translate(0); }
    20% { transform: translate(-2px, 2px); }
    40% { transform: translate(2px, -2px); }
    60% { transform: translate(-2px, -2px); }
    80% { transform: translate(2px, 2px); }
    100% { transform: translate(0); }
  }

  .vibrate {
    animation: vibrate 0.5s linear;
  }

  .popup-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }




  /* Media queries for responsiveness */
  @media screen and (max-width: 1024px) {
    .nav-links {
      display: none; /* Hide the links by default */
    }
  
    .hamburger {
      display: flex; /* Show the hamburger icon */
    }
  
    .nav-links.active {
      display: flex; /* Show links when hamburger is clicked */
      left: 0;
    }
  
    .navbar {
      flex-direction: row-reverse; /* Reverses order to put the hamburger on the left */
    }
  
    .nav-links li {
      margin: 10px 0; /* Increase spacing between links in mobile view */
    }
  
    .book-button {
      width: 100%; /* Full width button on smaller screens */
    }
    
    .logo {
        width: 50px;
        margin-right: 350px;
    }

    .logo img {
        width: 700%;
        margin-top: -5px;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .hamburger {
      position: static; /* Revert to normal positioning on larger screens */
      margin-right: auto; 
    }
  }
  
  /* Adjustments for very small screens (below 480px) */
@media screen and (max-width: 480px) {
  
  .logo {
    width: 40px; 
    margin-right: 150px; 
  }

  .logo img {
    width: 500%; 
    margin-top: 0;
  }

  /* Adjust navbar padding */
  .navbar {
    padding: 10px 20px; 
  }

  /* Adjust hamburger menu */
  .hamburger {
    top: 5px; 
    left: 5px;
  }

  /* Adjust nav-links for better spacing on small screens */
  .nav-links.active {
    top: 50px; 
    right: 0;
    width: 80%; 
    margin-right: 0; 
  }

  /* Adjust spacing between nav items */
  .nav-links li {
    margin: 5px 0;
  }

  /* Book button full-width */
  .book-button {
    width: 90%; 
    margin: 10px auto; 
  }
}

/* Adjustments for mobile screens between 480px and 768px */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .logo {
    width: 40px;
    margin-right: 150px; 
  }

  .logo img {
    width: 350%; 
  }

  .navbar {
    padding: 15px 30px; 
  }

  .nav-links.active {
    width: 60%; 
  }

  .book-button {
    width: 80%;
    margin: 15px auto; 
  }
}
