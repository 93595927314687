.coaching-section {
    background-color: #15003b; /* Dark purple background */
    color: #fff; /* White text */
    padding: 60px 20px;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .coaching-header h1 {
    font-size: 2.8rem;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .coaching-header p {
    font-size: 1.4rem;
    margin-bottom: 40px;
    font-weight: 300;
  }
  
  .coaching-content {
    display: grid; 
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
    margin-bottom: 50px;
    flex-wrap: nowrap;
    gap: 5px;
  }
  
  .coaching-box {
    background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white background */
    padding: 30px;
    border-radius: 10px;
    width: 45%;
    margin: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    animation: fadeInUp 1.2s ease-out;
  }
  
  .coaching-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .coaching-box-right {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .pricing-section {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
    animation: fadeInUp 1.4s ease-out;
  }
  
  .pricing-details {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 8px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .pricing-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .skills-section {
    padding: 30px;
    margin-bottom: 50px;
    animation: fadeInUp 1.5s ease-out;
  }
  
  .skills-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .skills-list li {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 15px 25px;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .skills-list li:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  .locations-section {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    margin-bottom: 50px;
    border-radius: 10px;
    animation: fadeInUp 1.6s ease-out;
    display: flex;
  }
  
  .location-content {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  
  .location-images {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    gap: 15px;
    flex-wrap: wrap;
    width: 40%;
  }
  
  .location-image {
    width: 45%;
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .location-image:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .map {
    width: 320%;
    margin-right: 20px;
  }

  .map iframe {
    border-radius: 10px;
  }
  
  .testimonials-section {
    margin-bottom: 50px;
    animation: fadeInUp 1.8s ease-out;
  }
  
  .form-section {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    margin-bottom: 50px;
    border-radius: 10px;
    animation: fadeInUp 2s ease-out;
  }
  
  .payment-options {
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    margin-bottom: 30px;
    animation: fadeInUp 2.2s ease-out;
  }
  
  .payment-icons img {
    width: 50px;
    margin: 0 10px;
    transition: transform 0.3s;
  }
  
  .payment-icons img:hover {
    transform: scale(1.2);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
 /* Tablet view (max-width: 1024px) */
@media screen and (max-width: 1024px) {
    .coaching-content {
        display: grid; 
        grid-template-columns: repeat(2, 2fr);
        justify-items: center;
    }
  
    .coaching-box {
      width: 80%;
      margin-bottom: 20px;
    }
  
    .location-images {
      display: grid; 
      grid-template-columns: repeat(2, 1fr);
      gap: 15px; 
      justify-items: center; 
      width: 90%;
      margin-top: 20px;
    }
  
    .location-image {
      width: 100%;
      margin-bottom: 0;
    }
  
    .locations-section {
      flex-direction: column;
      align-items: center;
    }
  
    .location-content {
      width: 90%;
      margin-bottom: 20px;
    }

    .location-content h2 {
        text-align: center;
    }

    .location-content p {
        text-align: center;
    }
  
    .map {
      width: 90%;
      height: auto;
      padding-top: 56.25%;
      position: relative;
      margin-bottom: 15px;
    }
  
    .map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      margin-left: 5px;
    }
  
    .pricing-details,
    .skills-list {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card,
    .skills-list li {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  /* Phone view (max-width: 768px) */
  @media screen and (max-width: 768px) {
    .coaching-content {
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        justify-items: space-between;
        gap: 30px;
    }
  
    .coaching-box {
      width: 80%;
      margin-bottom: 20px;
    }
  
    .location-images {
      flex-direction: column;
      align-items: center;
    }
  
    .location-image {
      width: 80%;
      margin-bottom: 20px;
    }
  
    .locations-section {
      flex-direction: column;
      align-items: center;
    }
  
    .location-content {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .map {
      width: 100%;
      height: auto;
      padding-top: 56.25%;
      position: relative; 
      margin-bottom: 20px;
    }
  
    .map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      margin-left: 8px;
    }
  
    .pricing-details,
    .skills-list {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card,
    .skills-list li {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  /* Small phone view (max-width: 380px) */
@media screen and (max-width: 380px) {
  .map {
      width: 90%;
      padding-top: 56.25%;
  }

  .map iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
  }

  .coaching-section {
      padding: 20px 8px; 
  }
}