/* Main app container styling */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Smooth scrolling effect */
html {
  scroll-behavior: smooth;
}

/* General section styles */
section {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

/* Utility classes */
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.full-height {
  height: 100vh;
}

button,
a {
  transition: all 0.3s ease;
}