/* EnrollmentForm.css */

/* Enrollment Section */
.enrollment-section {
  background-color: #15003b; /* Dark purple background */
  color: #fff; /* White text */
  padding: 60px 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Enrollment Form */
.enrollment-form {
  background-color: rgba(255, 255, 255, 0.05); /* Slightly transparent background */
  padding: 40px 30px;
  border-radius: 20px;
  max-width: 800px;
  margin: 0 auto; /* Center the form horizontally */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1.5s ease-out;
  margin-top: -80px;
}

/* Form Section Heading */
.form-section h2 {
  font-size: 2.8rem;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: fadeInUp 1s ease-out;
}

.form-section p {
  font-size: 1.4rem;
  margin-bottom: 40px;
  font-weight: 300;
  animation: fadeInUp 1.2s ease-out;
}

/* Form Group */
.form-group {
  margin-bottom: 25px;
  opacity: 0;
  animation: fadeInUp 1.4s ease-out forwards;
  animation-delay: 0.3s;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
  margin-left: 20px;
}

/* Contact Method Options */
.contact-option {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.contact-option input[type="radio"] {
  display: none;
}

.contact-option img {
  width: 30px;
  margin-right: 8px;
}

/* Styling the selected option */
.contact-option.selected {
  background-color: #ffc107;
  color: #15003b;
  transform: scale(1.05);

}



.form-group input,
.form-group textarea {
  width: 90%;
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent */
  color: #fff;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  animation: fadeInUp 1.6s ease-out forwards;
  animation-delay: 0.4s;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.form-group input:focus,
.form-group textarea:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
  transform: scale(1.02);
}

/* Submit Button */
.submit-button {
  width: 50%;
  padding: 15px;
  border-radius: 8px;
  border: none;
  background-color: #ffc107; /* Bright yellow */
  color: #15003b;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fadeInUp 1.8s ease-out forwards;
  animation-delay: 0.5s;
}

.submit-button:hover {
  background-color: #fdeec1;
  transform: translateY(-5px) rotate(-2deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


/* Payment Options */
.payment-options {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  opacity: 0;
  animation: fadeInUp 2s ease-out forwards;
  animation-delay: 0.6s;
}

.payment-options h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  animation: fadeInUp 2s ease-out forwards;
  animation-delay: 0.7s;
}

.payment-options p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  animation: fadeInUp 2.2s ease-out forwards;
  animation-delay: 0.8s;
}

.payment-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  animation: fadeInUp 2.4s ease-out forwards;
  animation-delay: 0.9s;
}

.payment-icons img {
  width: 50px;
  transition: transform 0.3s;
}

.payment-icons img:hover {
  transform: scale(1.2);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */

/* Tablet view (max-width: 1024px) */
@media screen and (max-width: 1024px) {
  .enrollment-section {
    padding: 40px 15px;
  }

  .enrollment-form {
    padding: 30px 25px;
    max-width: 700px;
  }

  .form-section h2 {
    font-size: 2.5rem;
  }

  .form-section p {
    font-size: 1.2rem;
  }

  .form-group label {
    font-size: 1.1rem;
  }

  .contact-method-options {
    flex-wrap: wrap;
    gap: 15px;
  }

  .contact-option {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .contact-option img {
    width: 18px;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px;
    font-size: 0.95rem;
  }

  .submit-button {
    padding: 12px;
    font-size: 1.1rem;
  }

  .payment-options h2 {
    font-size: 1.8rem;
  }

  .payment-options p {
    font-size: 1.1rem;
  }

  .payment-icons img {
    width: 45px;
  }
}

/* Phone view (max-width: 768px) */
@media screen and (max-width: 768px) {
  .enrollment-section {
    padding: 30px 10px;
  }

  .enrollment-form {
    padding: 20px 15px;
    max-width: 100%;
  }

  .form-section h2 {
    font-size: 2rem;
  }

  .form-section p {
    font-size: 1rem;
  }

  .form-group label {
    font-size: 1rem;
  }

  .contact-method-options {
    flex-direction: column;
    gap: 10px;
  }

  .contact-option {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .contact-option img {
    width: 16px;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 0.9rem;
  }

  .submit-button {
    padding: 10px;
    font-size: 1rem;
  }

  .payment-options h2 {
    font-size: 1.5rem;
  }

  .payment-options p {
    font-size: 1rem;
  }

  .payment-icons {
    flex-wrap: wrap;
    gap: 10px;
  }

  .payment-icons img {
    width: 40px;
  }
}
